@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");

@import url("https://cdn.linearicons.com/free/1.0.0/icon-font.min.css");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css');

html,body{
    scroll-behavior: smooth;
}

.nav-container-root{
    display: none !important;
}

.animated-container{
    width: 100%;
    height: 100%;
}

.animated-wrapper{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden
}
@media only screen and (max-width: 1024px) {
    .active-menu{
        max-height: 100vh;
        overflow: hidden;
    }

    .particles-wrapper{
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 0;
    }
    .particles-wrapper canvas {
        transition: opacity 225ms linear;
        opacity: 0;
    }
    .home .particles-wrapper canvas {
        opacity: 0.3;
    }
}

.page-content {
    width: 100vw;
    height: 100vh;
}

@media only screen and (min-width: 1024px) {
    .root canvas{
        background: linear-gradient(#9d2bf3,#5050f5);
    }

    .root .page-content {
        position: fixed;
        left: 50%;
        top: 50%;
        width: calc(100% - 30px);
        height: 100%;
        transform: translate(-50%,-50%);
        max-width: 1280px;
        max-height: 80vh;
        display: flex;
        background-color: #444;
        border-radius: 32px;
    }

    .nav-container-root{
        position: absolute;
        display: block !important;
        right: -80px;
        top: 0;
        background-color: #444;
        padding: 25px 15px !important;
        margin: 0;
        border-radius: 25px;
    }
    
    .nav-container-root li {
        margin: 25px 0;
    }

    .nav-container-root li:first-child{
        margin-top: 0;
    }
    .nav-container-root li:last-child{
        margin-bottom: 0;
    }

    .nav-container-root .menu-icon{
        font-size: 30px;
        display: block !important;
    }

    .nav-container-root .menu-text{
        display: none !important;
    }

    .animated-container{
        border-radius: 5px;
    }

    .animated-wrapper{
        background-color: #222;
        border-radius: 35px;
    }
}
